import gql from 'graphql-tag'

export const ADD_CANDIDATE_NOTIFY = gql `
 mutation addCandidateNotify($candidateId:ID,$idType:String,$type:String,$entityName:String,$date:String,$description:String){
    addCandidateNotify(candidateId:$candidateId,idType:$idType,type:$type,entityName:$entityName,date:$date,description:$description){
       content{
          idType
          type
          view
      }
    }
  }
`