<template>
  <v-container class="fill-heigt pt-8" fluid>
    <!-- <pre>
      {{candidate.belongsToMany?candidate.belongsToMany.length:'nada'}}
    </pre>  -->
    <v-row class="d-flex flex-row justify-space-between align-center px-3">
      <div>
        <!-- <v-btn icon color="primary" @click="routerBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn> -->
      </div>
      <div>
        <v-chip
          v-if="candidate.belongsToMany.length"
          link
          label
          outlined
          color="primary"
          class="mx-2"
          @click="openDialog()"
          >{{ $t("Editprofile") }}
        </v-chip>
        <!-- <v-chip
          link
          label
          outlined
          color="primary"
          class="mx-2"
          @click="dialogNote = true"
          >{{ $t("Notepad") }}
        </v-chip> -->

        <template>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                dark
                @click="dialogList = !dialogList"
              >
                <v-icon small color="#fff" left>mdi-plus</v-icon>
                {{ $t("AddtoFavorites") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_favlist_candidate')"></div>
          </v-tooltip>
        </template>
      </div>
    </v-row>
    <v-row class="mt-7">
      <v-col cols="12" md="9" v-if="vacancy">
        <profile-details
          :candidate="getCandidate"
          :filter="filter"
          :vacancies="vacancies"
        />
      </v-col>
      <v-col cols="12" md="3" class="pt-0">
        <!-- <div class="mb-2 text-uppercase">{{ $t("Profilesnotseen") }}</div> -->
        <!-- <v-divider class="py-3"></v-divider> -->
        <!-- <template > -->
        <!-- <v-row>
          <v-col class="list">
            <div v-for="can in candidates" :key="can.id">
              <v-card class="mb-2 pa-5 py-5" @click="go(can.id)">
                <v-list-item>
                  <v-list-item-avatar class="mx-auto" color="grey" size="38">
                    <img
                      :src="
                        can.photo
                          ? `${apiUrl}/images/candidate/${can.photo}`
                          : `/avatar.png`
                      "
                      alt="John"
                    />
                  </v-list-item-avatar>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 font-weight-medium">
                        <strong>{{ can.name.split(" ")[0] }}</strong>
                        {{ can.lastName.split(" ")[0] }}</v-list-item-title
                      >
                      <div class="caption text--disabled subtitle">
                        <span style="color: #faa638">{{ can.profession }}</span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item>
              </v-card>
            </div>
          </v-col>
        </v-row> -->
        <!-- </template> -->
      </v-col>
    </v-row>
    <candidate-list-dialog
      v-if="dialogList"
      :candidateId="candidateId"
      :dialog="dialogList"
      @fetchListFav="$emit('fetchListFav')"
      @close="dialogList = false"
    />
    <note-candidate-dialog
      v-if="dialogNote"
      :candidateId="candidate"
      :dialog="dialogNote"
      @close="dialogNote = false"
    />
    <edit-profile
      v-if="editCandidateDialog"
      :dialog="editCandidateDialog"
      :CurrentCandidate="candidate"
      @close="openDialog()"
    />
  </v-container>
</template>

<script>
import { API_URL } from "@/api";
import {
  GET_CANDIDATE_BY_ID_QUERY,
  GET_CANDIDATES_ENTITY_QUERY,
} from "./../graphql/Query";
import { UPDATED_CANDIDATE } from "./../graphql/subscription";
import { GET_VACANCY_BY_ID_QUERY } from "../../vacancy/graphql/Query.resolver";
import { mapActions, mapGetters } from "vuex";
import ProfileDetails from "../components/ProfileDetails.vue";
import CandidateListDialog from "../components/CandidateListDialog.vue";
import NoteCandidateDialog from "./../components/NoteCandidateDialog.vue";
import EditProfile from "./../components/EditProfile.vue";
export default {
  name: "Profile",
  components: {
    CandidateListDialog,
    ProfileDetails,
    NoteCandidateDialog,
    EditProfile,
  },
  props: ["candidate_", "vacancy_Id", "vacancies"],
  apollo: {
    candidate: {
      query: GET_CANDIDATE_BY_ID_QUERY,
      variables() {
        return {
          id: this.candidateId ? this.candidateId : this.candidate_.id,
        };
      },
      skip() {
        return this.skipQueryC;
      },
      result({ data, loading }) {
        if (!loading) {
          this.candidate = data.candidate;
        }
      },
    },
    candidates: {
      query: GET_CANDIDATES_ENTITY_QUERY,
      variables() {
        return { filterCandidate: this.filter };
      },
      fetchPolicy: "no-cache",
      skip() {
        return this.skipQueryCacndidates;
      },
      result({ data, loading }) {
        if (!loading) {
          console.log("lendo dados");
          this.candidates = data.candidates.candidates;
          var index = this.candidates.findIndex(
            (i) => i.id === this.candidateId
          );
          console.log(index);
          if (index > -1) {
            this.candidates.splice(index, 1);
          }
        }
      },
    },
    vacancy: {
      query: GET_VACANCY_BY_ID_QUERY,
      variables() {
        return {
          id: this.vacancyId ? this.vacancyId : this.vacancy_Id.id,
        };
      },
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.vacancy = data.vacancy;
        }
      },
    },
    $subscribe: {
      message: {
        query: UPDATED_CANDIDATE,
        result({ data }) {
          if (data.updateCandidate.id == this.candidate.id) {
            this.candidate = data.updateCandidate;
          }
        },
      },
    },
  },
  data: () => ({
    apiUrl: API_URL,
    candidateId: undefined,
    candidate: {},
    dialogNote: false,
    candidates: [],
    dialogList: false,
    filter: {
      areasInterest: undefined,
      belongsToMany: undefined,
      favorites: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      limit: 100,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
    },
    skipQueryC: true,
    skipQueryCacndidates: true,
    skipQuery: true,
    vacancy: {},
    editCandidateDialog: false,
  }),
  created() {
    this.start();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      // getCandidate: "candidate/getCandidate",
    }),
    getCandidate() {
      if (this.$route.query.vacancyId) {
        return this.vacancy.candidacies.find(({ candidate }) =>
          candidate.id == this.candidateId
            ? this.candidateId
            : this.candidate_.id
        ).candidate;
      }
      return this.candidate;
    },
  },
  watch: {
    // candidate: function (val) {
    //  return this.candidate = val;
    // },
  },
  methods: {
    ...mapActions({}),
    start() {
      this.candidateId = this.$route.params.id
        ? this.$route.params.id
        : this.candidate_.id;
      // this.filter = this.$route.query.filter ? { ...this.$route.query.filter } : this.filter;
      // this.filter = this.$route.query.filter;
      this.filter.limit = 100;
      if (this.$route.query.vacancyId) {
        this.vacancyId = this.$route.query.vacancyId;
        this.getVacancy();
      } else {
        this.fetchCandidate();
        this.fetchCandidates();
      }
    },
    async fetchCandidate() {
      this.$apollo.queries.candidate.skip = false;
      await this.$apollo.queries.candidate.refetch();
    },
    async fetchCandidates() {
      this.$apollo.queries.candidates.skip = false;
      await this.$apollo.queries.candidates.refetch();
    },
    async getVacancy() {
      this.$apollo.queries.vacancy.skip = false;
      await this.$apollo.queries.vacancy.refetch();
    },
    openDialog() {
      this.editCandidateDialog = !this.editCandidateDialog;
    },
    go(candidateId) {
      this.candidateId = candidateId;
      var index = this.candidates.findIndex((i) => i.id === this.candidateId);
      console.log(index);
      if (index > -1) {
        this.candidates.splice(index, 1);
      }
      if (this.$route.query.vacancyId) {
        this.vacancyId = this.$route.query.vacancyId;

        this.getVacancy();
      } else {
        this.fetchCandidate();
        // this.fetchCandidates();
      }
    },
    routerBack() {
      switch (this.$route.query.from) {
        case "candidacies":
          this.$router.push({
            path: "/dashboard/entity/candidacies",
            query: { filter: this.filter },
          });
          break;

        case "ranking":
          this.$router.push({
            path: "/dashboard/entity/ranking",
            query: { filter: this.filter },
          });
          break;

        default:
          this.$router.push({
            path: "/dashboard/entity/candidates",
            query: { filter: this.filter },
          });
          break;
      }
    },
  },
};
</script>
<style scoped>
.list {
  height: 590px;
  overflow-y: auto;
}

::-webkit-scrollbar-thumb:vertical {
  height: 1px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb:horizontal {
  height: 1px;
  background-color: #fff;
}
</style>
