import { render, staticRenderFns } from "./SendEmailToCandidate.vue?vue&type=template&id=2e66cf8c"
import script from "./SendEmailToCandidate.vue?vue&type=script&lang=js"
export * from "./SendEmailToCandidate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports